'use client';
import { gsap } from 'gsap';
import { RefObject, useCallback, useEffect, useRef } from 'react';

import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { ForwardedTextLockupRef } from '~/components/molecules/TextLockups/TextLockups.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import AnimationTracker from '~/utils/singletons/AnimationTracker';
import { EaseType } from '~/utils/singletons/Easing';

import HeroCard from './HeroCard/HeroCard';
import { ForwardedHeroCardRef } from './HeroCard/HeroCard.types';
import styles from './HeroCardsTransition.module.css';
import { HeroCardsTransitionProps } from './HeroCardsTransition.types';

const HeroCardsTransition = (props: HeroCardsTransitionProps) => {
  const { background, content, cards, className } = props;
  const hasAnimated = useRef(false);
  const $element = useRef<HTMLDivElement>(null);
  const $background = useRef<HTMLElement>(null);
  const $textWrapper = useRef<HTMLDivElement>(null);
  const $content = useRef<ForwardedTextLockupRef>(null);
  const $wall = useRef<HTMLDivElement>(null);
  const $cardsWrapper = useRef<HTMLDivElement>(null);
  const scrollTimeline = useRef<GSAPTimeline>();
  const $cards = useRef<ForwardedHeroCardRef[]>(Array(cards.length));

  const progressQuickTo = useRef<ReturnType<typeof gsap.quickTo>>();

  const textAnimation = useCallback(
    ({
      $ref,
      shouldWaitForFontLoad = true,
      paused = true,
      amplitude = 1,
    }: {
      $ref: RefObject<HTMLDivElement>;
      shouldWaitForFontLoad?: boolean;
      paused?: boolean;
      amplitude?: number;
    }) => {
      const tl = gsap.timeline({
        paused,
      });

      if (amplitude) {
        tl.timeScale(1 / amplitude);
      }

      const updateTimeline = () => {
        if ($ref.current) {
          tl.fromTo(
            $ref.current,
            {
              y: 200 * amplitude,
              opacity: 0,
            },
            {
              duration: 0.7,
              delay: 0.06,
              y: 0,
              clearProps: 'transform, opacity',
              ease: EaseType.DEFAULT,
              stagger: 0.07,
            },
            0,
          );
          tl.fromTo(
            $ref.current,
            {
              opacity: 0,
            },
            {
              duration: 0.5,
              opacity: 1,
              stagger: 0.07,
              clearProps: 'transform, opacity',
            },
            // seconds past the start of the previous animation
            '<+=0.1',
          );
        }
      };

      if (shouldWaitForFontLoad) {
        document.fonts.ready.then(updateTimeline);
      } else {
        updateTimeline();
      }

      return tl;
    },
    [],
  );

  useEffect(() => {
    if (
      $element.current &&
      $content.current?.$element &&
      $content.current?.$element?.current
    ) {
      const markAnimationComplete = AnimationTracker.addPendingAnimation();
      hasAnimated.current = true;
      const tl = gsap.timeline({
        delay: 1,
        onComplete: () => {
          markAnimationComplete();
        },
      });
      tl.add(
        textAnimation({
          $ref: $content.current?.$element,
          shouldWaitForFontLoad: false,
          paused: false,
          amplitude: 1.7,
        }),
        0,
      );
      tl.add('image animation', 0.22);
      tl.fromTo(
        $cards.current.map((ref) => ref.$element.current),
        { '--card-entry-progress': 0 },
        {
          '--card-entry-progress': 1,
          stagger: {
            from: 'center',
            amount: 0.2,
            ease: 'power2.out',
          },
          duration: 1,
          ease: EaseType.OUT_SOFT,
        },
        'image animation',
      );
      let animationIndex = 0;

      $cards.current.forEach((cardRef, index) => {
        if (cards[index].shouldAnimate) {
          tl.add(cardRef.enter(), animationIndex === 0 ? '>-0.5' : '<+0.6');
          animationIndex++;
        }
      });
      return markAnimationComplete;
    }
  }, [textAnimation, cards]);

  useLayoutEffect(() => {
    scrollTimeline.current = gsap.timeline({ paused: true });

    if ($textWrapper.current) {
      scrollTimeline.current.to(
        $textWrapper.current,
        {
          y: '18vh',
          duration: 1,
        },
        0,
      );
      // Text
      scrollTimeline.current.to(
        $textWrapper.current,
        {
          opacity: 0,
          duration: 0.3,
        },
        0.02,
      );
    }
    // Background image
    scrollTimeline.current.to(
      $background.current,
      {
        opacity: 0,
        duration: 0.5,
      },
      0,
    );

    scrollTimeline.current.to(
      $element.current,
      {
        '--parallax-progress': 1,
        duration: 1,
      },
      0,
    );
    scrollTimeline.current.fromTo(
      $cards.current.map((ref) => ref.$element.current),
      { '--card-scroll-progress': 1 },
      {
        '--card-scroll-progress': 0.4,
        stagger: {
          from: 'edges',
          amount: 0.35,
          ease: 'power2.in',
        },
        duration: 0.7,
      },
      0,
    );

    progressQuickTo.current = gsap.quickTo(scrollTimeline.current, 'progress', {
      duration: 0.05,
      ease: 'linear',
    });
  }, []);

  const onProgress = useCallback(
    (progress: number) => {
      // console.log(ScrollPosition.y);
      if (progressQuickTo.current) {
        let formattedProgress = progress;
        if (props.sectionIndex !== 0 || props.moduleIndex !== 0) {
          // If it's not the first module, we remap the progress from -1 to 1 so the state when
          // the wall is in the middle of the viewport is the comp position
          formattedProgress = gsap.utils.mapRange(0, 1, -1, 1)(progress);
        }

        progressQuickTo.current(Math.round(formattedProgress * 1000) / 1000);
      }
    },
    [props.sectionIndex, props.moduleIndex],
  );

  useScrollProgress($element, onProgress);

  $cards.current = [];

  return (
    <ModuleWrapper
      className={cn(styles.heroCardsTransition, className)}
      ref={$element}
      {...props}
    >
      {background.media && (
        <EnhancedMedia
          overlay={background.overlay}
          className={styles.backgroundWrapper}
        >
          <Media
            ref={$background}
            sanityMedia={background.media.sanityMedia}
            className={cn(styles.backgroundImageContainer)}
            quality={90}
            aspectRatio={1.5}
          />
        </EnhancedMedia>
      )}
      <div className={styles.contentWrapper}>
        <div ref={$textWrapper}>
          <TextLockup
            ref={$content}
            value={content.blocks}
            className={styles.content}
            lockupOptions={content.lockupOptions}
          />
        </div>
        <div className={styles.wall} ref={$wall}>
          <div
            aria-hidden={true}
            className={styles.cardsWrapper}
            ref={$cardsWrapper}
          >
            {cards.map((card, index) => {
              if (!card) return;
              return (
                <HeroCard
                  key={card._key}
                  {...card}
                  className={styles.card}
                  ref={(element: ForwardedHeroCardRef) =>
                    element &&
                    addToRefArray({ element, refArray: $cards, index })
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default HeroCardsTransition;
