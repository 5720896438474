'use client';
import { gsap } from 'gsap';
import { useCallback, useRef } from 'react';

import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { TextBlockPropertiesGroup } from '~/components/molecules/TextLockups/TextLockups.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import {
  cn,
  isBreakpointOrGreater,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';
import { EaseType } from '~/utils/singletons/Easing';

import Sequence from '../../Sequence/Sequence';
import styles from './Prompter.module.css';
import { PrompterProps } from './Prompter.types';

const useUIStore = UIStore;

const Prompter = (props: PrompterProps) => {
  const { sequence, className, fallbackImage, prompterContent } = props;
  const $element = useRef<HTMLDivElement>(null);
  const $textWrapper = useRef<HTMLDivElement>(null);
  const $prompterSizer = useRef<HTMLDivElement>(null);
  const $blockRefs = useRef<TextBlockPropertiesGroup[]>([]);
  const wordsEls = useRef<HTMLElement[]>([]);
  const breakpoint = useUIStore((state) => state.breakpoint);

  const timeline = useRef<GSAPTimeline>();

  const renderProps = {
    canvasClassName: styles.canvas,
    fallbackImage: fallbackImage,
  };

  const onProgress = useCallback((progress: number) => {
    if (timeline.current) timeline.current.progress(progress);
  }, []);

  useScrollProgress($prompterSizer, onProgress, {
    startOnMiddleOfScreen: false,
    finishOnMiddleOfScreen: false,
  });

  useLayoutEffect(() => {
    const windowHeight = UIStore.getState().windowHeight || window.innerHeight;
    const tl = gsap.timeline({ paused: true });
    const isSmallBreakpoint = !isBreakpointOrGreater(breakpoint, 'md');
    const sizerBoundingRect = $prompterSizer.current?.getBoundingClientRect();

    // convert parallax value to int (use same formula as parallax calc value in css, since we cannot compute that value, we have to replicate the formula)
    const sectionParallaxValue =
      $prompterSizer?.current && !isSmallBreakpoint
        ? parseInt(
            getComputedStyle($prompterSizer?.current).getPropertyValue(
              '--parallax-overlap-y',
            ),
          ) * 10
        : 160;

    const normalizedRatioWindowHeightToSequenceHeight = sizerBoundingRect
      ? windowHeight / sizerBoundingRect.height
      : 0.2;
    const normalizedRatioSectionParallax = sizerBoundingRect
      ? sectionParallaxValue / sizerBoundingRect.height
      : 0.2;

    const startTime = isSmallBreakpoint
      ? normalizedRatioWindowHeightToSequenceHeight * 0.55
      : normalizedRatioWindowHeightToSequenceHeight * 0.8;
    // start with delay because of overlap with hero, we want to start fading in the sequence slightly after
    tl.add('start', startTime);

    tl.fromTo(
      $element.current,
      {
        opacity: 0,
      },
      { opacity: 1, ease: EaseType.OUT, duration: 0.15 },
      'start',
    );
    tl.fromTo(
      $textWrapper.current,
      {
        y: windowHeight * 0.5,
        opacity: 0,
      },
      { y: 0, opacity: 1, ease: EaseType.OUT, duration: 0.15 },
      isSmallBreakpoint ? '<+0.08' : '<+0.05',
    );

    wordsEls.current = $blockRefs.current.reduce((refs, currentBlock) => {
      if (currentBlock.wordEls) {
        tl.fromTo(
          currentBlock.wordEls,
          {
            opacity: 0.1,
          },
          {
            opacity: 1,
            stagger: {
              amount:
                1 -
                startTime -
                (normalizedRatioWindowHeightToSequenceHeight -
                  normalizedRatioSectionParallax) -
                0.05,
            },
            duration: 0.05,
          },
          '<',
        );
        return refs.concat(currentBlock.wordEls);
      }
      return refs;
    }, [] as HTMLElement[]);

    tl.to(
      $textWrapper.current,

      {
        y: -sectionParallaxValue * 2,
        duration:
          normalizedRatioWindowHeightToSequenceHeight -
          normalizedRatioSectionParallax +
          0.05,
      },
      '>',
    );

    tl.to(
      $element.current,

      {
        opacity: 0,
        duration:
          normalizedRatioWindowHeightToSequenceHeight -
          normalizedRatioSectionParallax -
          0.07,
      },
      '<+0.05',
    );

    tl.add('end', 1);

    timeline.current = tl;

    return () => {
      if (timeline.current) timeline.current.kill();
    };
  }, [breakpoint]);

  const renderPrompt = () => {
    if (!prompterContent) return;
    const prompterText = prompterContent.blocks.find(
      (block) =>
        typeof block.children[0].text === 'string' &&
        block.children[0].text.length > 0,
    )?.children[0].text as string;

    return (
      <div className={styles.contentWrapper} aria-label={prompterText}>
        <div className={styles.contentOverlay}></div>
        <div aria-disabled className={styles.textWrapper} ref={$textWrapper}>
          <TextLockup
            lockupOptions={prompterContent.lockupOptions}
            value={prompterContent.blocks}
            className={styles.promptContent}
            blockRefs={$blockRefs}
          />
        </div>
      </div>
    );
  };

  return (
    <ModuleWrapper
      className={cn(styles.prompter, className)}
      {...props}
      ref={$element}
    >
      <div className={styles.prompterSizer} ref={$prompterSizer}></div>
      {sequence.type === 'video' && (
        <Sequence
          {...renderProps}
          sequence={sequence}
          className={styles.sequence}
        >
          {renderPrompt()}
        </Sequence>
      )}
      {sequence.type === 'image' && (
        <Sequence
          {...renderProps}
          sequence={sequence}
          className={styles.sequence}
        >
          {renderPrompt()}
        </Sequence>
      )}
    </ModuleWrapper>
  );
};

export default Prompter;
