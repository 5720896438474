import {
  FocusEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import SvgCaret from '~/assets/svg/caret.svg';
import SvgI from '~/assets/svg/i.svg';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import Media from '~/components/molecules/Media/Media';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import { CMSBullet, CMSPricing } from '../../Pricing.types';
import { PricingCardDetails } from '../PricingSideBySide.types';
import styles from './PricingUpgradeCard.module.css';
import { OVERFLOW_DETAILS_COUNT } from './PricingUpgradeCard.types';

type PricingUpgradeCardProps = {
  pricing: CMSPricing;
  card: PricingCardDetails;
};

const PricingUpgradeCard = ({ card, pricing }: PricingUpgradeCardProps) => {
  const bullets = useRef<HTMLElement[]>([]);
  const overflow = useRef<HTMLOListElement>(null);
  const product = useMemo(
    () => pricing.products.find((tier) => tier.slug === card.productTier),
    [card, pricing],
  );
  const breakpoint = useUIStore((state) => state.breakpoint);
  const [detailsPrimary, setDetailsPrimary] = useState<CMSBullet[]>([]);
  const [detailsOverflow, setDetailsOverflow] = useState<CMSBullet[]>([]);
  const [showDetailsOverflow, setShowDetailsOverflow] =
    useState<boolean>(false);

  // for small breakpoints, only a subset of the details will be visible
  // so that we can fit the two comparison cards in the viewport. The remainder
  // (overflow) is instead collapsed.
  useEffect(() => {
    if (!breakpoint || !product) {
      return;
    }
    if (breakpoint.name === 'sm') {
      setDetailsPrimary(
        product.detailsBullets.slice(0, OVERFLOW_DETAILS_COUNT),
      );
      setDetailsOverflow(product.detailsBullets.slice(OVERFLOW_DETAILS_COUNT));
    } else {
      setDetailsPrimary(product.detailsBullets);
      setDetailsOverflow([]);
    }
  }, [breakpoint, product]);

  const showTooltipHandler = useCallback(() => {
    return (
      e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
    ) => {
      const item = e.currentTarget;
      const list = bullets.current;
      for (const bullet of list) {
        if (bullet && bullet.contains(item)) {
          bullet.setAttribute('data-is-active', 'true');
        }
      }
    };
  }, []);

  const hideTooltipHandler = useCallback(() => {
    return (
      e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
    ) => {
      const item = e.currentTarget;
      const list = bullets.current;
      for (const bullet of list) {
        if (bullet && bullet.contains(item)) {
          bullet.removeAttribute('data-is-active');
        }
      }
    };
  }, []);

  const toggleDetailsOverflow = useCallback(() => {
    setShowDetailsOverflow(!showDetailsOverflow);
  }, [showDetailsOverflow]);

  if (!product) {
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          Product tier {card.productTier} not found on pricing document.
        </div>
      </div>
    );
  }

  const renderBulletItem = (bullet: CMSBullet, className?: string) => {
    return (
      <li
        className={cn(styles.bulletItem, className)}
        key={bullet._key}
        ref={(element) =>
          bullet.description && addToRefArray({ element, refArray: bullets })
        }
      >
        {bullet.title}
        {bullet.description && (
          <>
            <button
              className={styles.cardDetailBulletSvgButton}
              aria-label={bullet.description}
              onMouseOver={showTooltipHandler()}
              onFocus={showTooltipHandler()}
              onMouseOut={hideTooltipHandler()}
              onBlur={hideTooltipHandler()}
            >
              <SvgI className={styles.cardDetailBulletSvg} />
            </button>
            <div className={styles.cardDetailBulletTooltip}>
              <span className={styles.cardDetailBulletTooltipText}>
                {bullet.description}
              </span>
              <figure className={styles.cardDetailBulletTooltipTail} />
            </div>
          </>
        )}
      </li>
    );
  };

  return (
    <div className={styles.container}>
      {card.background && (
        <div className={styles.background}>
          <Media
            className={styles.backgroundMedia}
            sanityMedia={card.background.media.sanityMedia}
            isDisplayed={true}
          />
        </div>
      )}
      <div className={styles.content}>
        <div
          className={cn(
            styles.header,
            card.showBulletList ? styles.headerWithSpacer : undefined,
          )}
        >
          <div className={styles.eyebrow}>{card.eyebrow}</div>
          <div className={styles.title}>{card.title}</div>
          <div className={styles.subtitle}>{card.subtitle}</div>
          <div className={styles.label}>per member</div>
        </div>
        {card.showBulletList && (
          <div className={styles.bullets}>
            {card.bulletListTitle && (
              <div className={styles.bulletsTitle}>{card.bulletListTitle}</div>
            )}
            <div className={styles.bulletsListWrapper}>
              <ol>
                {detailsPrimary.map((bullet) => renderBulletItem(bullet))}
              </ol>
              <ol
                className={cn(
                  styles.bulletsOverflowList,
                  showDetailsOverflow
                    ? styles.bulletsOverflowListExpanded
                    : undefined,
                )}
                ref={overflow}
              >
                {detailsOverflow.map((bullet) => renderBulletItem(bullet))}
              </ol>
            </div>
            {detailsOverflow.length > 0 && (
              <button
                className={styles.bulletItemsViewAllLink}
                onClick={toggleDetailsOverflow}
              >
                View All
                <SvgCaret
                  className={cn(
                    styles.bulletItemCaret,
                    showDetailsOverflow
                      ? styles.bulletItemCaretExpanded
                      : undefined,
                  )}
                />
              </button>
            )}
          </div>
        )}
        {card.link && (
          <Button
            className={styles.cta}
            to={card.link}
            buttonVariant="primary"
            buttonColorScheme="white"
          >
            {card.link.label}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PricingUpgradeCard;
