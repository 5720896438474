'use client';

import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './PricingSideBySide.module.css';
import { PricingSideBySideProps } from './PricingSideBySide.types';
import PricingUpgradeCard from './PricingUpgradeCard/PricingUpgradeCard';

const PricingSideBySide = (props: PricingSideBySideProps) => {
  return (
    <ModuleWrapper
      className={cn(styles.pricingSideBySide, props.className)}
      {...props}
    >
      <div className={styles.container}>
        {props.cards.map((card) => (
          <PricingUpgradeCard
            key={card._key}
            pricing={props.pricing}
            card={card}
          />
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default PricingSideBySide;
